.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.instagram {
  color: transparent !important;
  padding: 0.1em;
  border-radius: 5px;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent !important;
}

/* .topbar {
  background-color: transparent;
  padding: 0;
}

.topbar .container .row {
  margin: -7px;
  padding: 0;
}

.topbar .container .row .col-md-12 {
  padding: 0;
} */

ul.lan-btn {
  display: flex;
  /* justify-content: end; */
  gap: 1.2rem;
  border: none;
  margin: 8px 0;
  /* padding: 0; */
  list-style: none;
}

ul.lan-btn li {
  border: none;
  margin: 0;
  padding: 0;
}

ul.lan-btn li a {
  text-decoration: none;
  color: inherit;
}

ul.lan-btn li i {
  margin: 0;
}

ul.lan-btn li {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  cursor: pointer;
  /* font-size: 20px; */
  /* font-weight: 500; */
  /* margin: 0 5px; */
  border: 0px solid #2d2d2d;
  /* padding: 5px 0 0; */
  /* display: inline-block; */
  text-align: center;
  /* height: 30px; */
  color: rgba(0, 0, 0, 0.747);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.nav-link {
  color: rgba(0, 0, 0, 0.747) !important;
}

.nav-link:hover {
  color: #7f1919 !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #7f1919 !important;
}

.navbar-item > a {
  text-align: center;
}

.navbar-nav .nav-link.active:after {
  content: "";
  background-image: linear-gradient(253.58deg, rgba(102, 82, 10, 0.849) 1.55%, #7f1919 95.8%);
  border-radius: 4px;
  width: auto;
  display: block;
  height: 3px;
  margin: 0 auto -3px;
}

.navbar-nav .dropdown-menu {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.navbar-nav .dropdown-menu.show { /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
  max-height: 300px;
  opacity: 1;
}

.navbar-nav .dropdown-menu {
  background: rgba(102, 82, 10, 0.849);
  padding: 0;
  border: 0;
  border-radius: 0;
}

.navbar{
  position: fixed !important;
  top: 0 !important; /* Ensures it stays at the top */
  left: 0 !important;
  right: 0 !important;
  z-index: 1030 !important; 
  background-attachment: fixed !important;
  background-image: url('components/images/background/bg_6.png') !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
/* 
.navbar-nav .dropdown-menu > li { 
} */

.navbar-nav .dropdown-menu > li > a {
  padding: 8px 6px;
  color: aliceblue;
  font-weight: 400;
  background: transparent;
}

.navbar-nav .dropdown-menu > li:hover,
.navbar-nav .dropdown-menu > li:hover > a,
.navbar-nav .dropdown-menu > li > a:hover {
  cursor: pointer;
  background-color: #7f1919;
  color: aliceblue;
}

.text-align-justify {
  text-align: justify;
}

.card-hover:hover {
  cursor: pointer;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.sub-underline {
  height: 4px;
  width: 4rem;
  border-radius: 4px;
  background-color: black;
}

.vertical-timeline-element-date {
  color: black;
  font-weight: 800;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 78vh;
}

@media (max-width: 767px) {
  .carousel-item  > img {
    height: 12.7rem;
  }
}
/* @media (max-width: 576px) {
  .carousel-item  > img {
    height: 13rem;
  }
} */

.band {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
}
@media (max-width: 30em) {
  .band {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 60em) {
  .band {
    grid-template-columns: repeat(4, 1fr);
  }
  .upcoming-band{
    grid-template-columns: repeat(6, 1fr) !important;
  }
}

.my-card {
  background: transparent;
  text-decoration: none;
  color: #444;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-height: 80%;
  position: relative;
  top: 0;
  transition: all 0.1s ease-in;
}
.my-card:hover {
  cursor: pointer;
  top: -2px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}
.my-card article {
  /* padding: 20px 120px ; */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.my-card h1 {
  font-size: 20px;
  margin: auto;
  color: #333;
}
.my-card p {
  flex: 1;
  line-height: 1.4;
  font-size: 20px;
}
.my-card span {
  font-size: 12px;
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 2em 0 0 0;
}
.my-card#maintain{
  box-shadow: none;
  top:0px;
  cursor:default;
}
.my-card .thumb {
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 100%
}
.upcoming{
  border: 2px solid rgb(0, 0, 0,0.2);
}
.desc{
  /* margin-left: 600px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; /* Adjust as needed */
  width: 85vw;
  text-align: center;
}
@media (min-width: 576px) {
  #press-modal.modal-dialog{
      max-width: 750px;
  }
}
@media (min-width: 60em) {
  .item-0#upcoming {
    grid-column: 1/span 2;
  }
  .item-1#upcoming {
    grid-column: 3/span 2;
  }
  .item-2#upcoming {
    grid-column: 5/span 2;
  }
  .item-0 {
    grid-column: 2/span 2;
  }
 .item-1 {
    grid-column: 3/span 2;
  }
  .item-0#multiple{
    grid-column: 1/span 1;
  }
  .item-1#multiple{
    grid-column: 2/span 1;
  }
  .item-2#multiple{
    grid-column: 3/span 1;
  }
  h1 {
    font-size: 24px;
  }
}
@media (max-width: 30em) {
  h1 {
    font-size: 24px;
  }
  [class^="item-"]{
    grid-column: 1/span 2;
  }
}
.my-card p.content{
  line-height: 1.4;
  font-size: 15px !important;
  cursor: default !important;
  color: #444 !important;
  letter-spacing: 0.05em !important;
  margin: 2em 0 0 0 !important;
}
.animated-icon {
  display: inline-block;
  animation: shiftPosition 1s infinite alternate;
}

@keyframes shiftPosition {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}

/* Carousel CSS */

.carousel-slider {
  width: 95%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: visible;
}

.image-container {
  padding: 15px;
  position: relative;
}

.slider-image {
  width: 100%;
  border-radius: 10px;
  cursor:pointer;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important;
  padding: 15px !important;
  border-radius: 50%;
}

.custom-arrow:hover{
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.prev-arrow {
  left: 10px !important; /* Move left arrow outside the slider */
  width: 50px !important;
  height: 50px !important;
}

.next-arrow {
  right: 10px !important; /* Move right arrow outside the slider */
  width: 50px !important;
  height: 50px !important;
}

.custom-arrow span {
  font-size: 20px;
}

.slick-list {
  padding: 50px !important ;
  overflow-x: hidden !important; /* Hide horizontal scroll */
  overflow-y: visible !important; /* Show vertical overflow if needed */
}

.slick-current {
  transform: scale(1.1); /* Increase the size of the center image */
  z-index: 1; /* Bring it to the front */
  transform-origin: center;
  transition: transform 0.8s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (min-width: 576px) {
  .singleModal{
    max-width: 650px !important;
  }
  .upcomingModal {
    max-width: 1000px !important;
  }
}

.image-title {
  position: absolute;
  bottom: 22px;
  left: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.5); 
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  opacity: 1; 
  transition: opacity 0.3s ease; 
}
.image-title-footer{
  background-color: rgba(0, 0, 0, 0.5); 
  width:100%;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  opacity: 1; 
  transition: opacity 0.3s ease; 
}

/* .image-container:hover .image-title {
  opacity: 1; 
}  */

.short-description-font {
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.747) !important; /* Adjust color if needed */
}

.upcoming-image {
  width: 100%;
  border-radius: 10px;
  cursor:pointer;
  padding-left: 20px;
  padding-right: 20px;
}

.event-title {
  position: absolute;
  color: rgba(0, 0, 0, 0.747);
  bottom: -10px;
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
}

.event_year {
  position: relative;
  color: #8d8c8c;
  left: 10px;
  padding-top: 5px;
  padding-bottom: 25px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* .pagination-controls button {
  margin: 0 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

.pagination-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-controls button {
  margin: 0 10px;
  padding: 8px 12px;
  background-color: #534c42;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.pagination-controls span {
  font-size: 16px;
}

.pagination-dots {
  display: flex;
  gap: 5px;
  margin: 0 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  cursor: pointer;
}

.dot.active {
  background-color: black; /* Highlight the current page dot */
}

/* AOS CSS */

[data-aos][data-aos][data-aos-duration="50"],body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms
}

[data-aos][data-aos][data-aos-delay="50"],body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms
}

[data-aos][data-aos][data-aos-duration="100"],body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s
}

[data-aos][data-aos][data-aos-delay="100"],body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s
}

[data-aos][data-aos][data-aos-duration="200"],body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s
}

[data-aos][data-aos][data-aos-delay="200"],body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s
}

[data-aos][data-aos][data-aos-duration="250"],body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s
}

[data-aos][data-aos][data-aos-delay="250"],body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s
}

[data-aos][data-aos][data-aos-duration="300"],body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s
}

[data-aos][data-aos][data-aos-delay="300"],body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s
}

[data-aos][data-aos][data-aos-duration="400"],body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s
}

[data-aos][data-aos][data-aos-delay="400"],body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s
}

[data-aos][data-aos][data-aos-duration="500"],body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s
}

[data-aos][data-aos][data-aos-delay="500"],body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s
}

[data-aos][data-aos][data-aos-duration="600"],body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s
}

[data-aos][data-aos][data-aos-delay="600"],body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s
}

[data-aos][data-aos][data-aos-duration="700"],body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s
}

[data-aos][data-aos][data-aos-delay="700"],body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s
}

[data-aos][data-aos][data-aos-duration="750"],body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s
}

[data-aos][data-aos][data-aos-delay="750"],body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s
}

[data-aos][data-aos][data-aos-duration="900"],body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s
}

[data-aos][data-aos][data-aos-delay="900"],body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s
}

[data-aos][data-aos][data-aos-duration="1000"],body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s
}

[data-aos][data-aos][data-aos-delay="1000"],body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s
}

[data-aos][data-aos][data-aos-duration="2000"],body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s
}

[data-aos][data-aos][data-aos-delay="2000"],body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s
}

[data-aos][data-aos][data-aos-duration="3000"],body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s
}

[data-aos][data-aos][data-aos-delay="3000"],body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s
}

[data-aos][data-aos][data-aos-easing=ease],body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease
}

[data-aos][data-aos][data-aos-easing=ease-out],body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0)
}

[data-aos=fade-up] {
  transform: translate3d(0,100px,0)
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1)
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0)
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg)
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg)
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0)
}


/*  About / Team Members Card Css   */

.ftco-section {
  /* padding: 8em 0; */
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.staff {
  border-radius: 4px;
  width: 100%;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
}

.staff .img {
  width: 100%;
  display: block;
  height: 420px;
  background-position: top center !important;
  position: relative;
}

.staff .text {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.staff .text span {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
